.css-9gtlb3 .simplebar-content {
  height:0 !important;
  background-color: 'transparent' !important;
}
@media only screen and (max-width: 1600px) {
  .image_center {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .width_drawer {
    width: 35% !important;
  }
  .cross_drawer {
    display: block !important;
    margin-top: 10px;
  }
  .admin_modal_pa {
    width: 75% !important;
    padding: 0px 5px 20px 5px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .heading_center {
    text-align: center !important;
  }

  .image_center {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .top_options {
    display: flex !important;
    flex-direction: column !important;
    height: 340px !important;
  }

  .top_column {
    display: flex !important;
    flex-direction: column !important;
  }

  .width_full {
    width: 100% !important;
  }

  .width_full_property {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .width_full_property_price {
    width: 100% !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .width_full_status {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .property_modal_header {
    padding: 20px 0px 12px 5px !important;
  }

  .margin_property {
    margin-right: 5px !important;
    padding-left: 5px !important;
  }

  .property_checkbox {
    width: 95% !important;
  }

  .admin_modal {
    width: 95% !important;
    padding: 20px 5px 20px 5px !important;
  }

  .admin_modal_pa {
    width: 95% !important;
    padding: 0px 5px 20px 5px !important;
  }

  .property_padding {
    padding: 5px !important;
  }
  .cross_drawer {
    display: block !important;
  }
  .width_drawer {
    width: 100% !important;
  }
  .property_Modal_Padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .image_Width {
    width: 100px !important;
  }
  .image_Margin {
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }
  .justify {
    justify-content: center !important;
  }
  .column {
    flex-direction: column !important ;
  }
  .margin {
    margin-bottom: 5px !important;
  }
  .width_agent {
    width: 65% !important;
  }
  .video_property {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .heading_center {
    text-align: center !important;
  }

  .image_center {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .top_options {
    display: flex !important;
    flex-direction: column !important;
    height: 340px !important;
  }

  .top_column {
    display: flex !important;
    flex-direction: column !important;
  }

  .width_full {
    width: 100% !important;
  }

  .width_full_property {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .width_full_property_price {
    width: 100% !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .width_full_status {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .property_modal_header {
    padding: 20px 0px 12px 5px !important;
  }

  .margin_property {
    margin-right: 5px !important;
    padding-left: 5px !important;
  }

  .property_checkbox {
    width: 95% !important;
  }

  .admin_modal {
    width: 95% !important;
    padding: 20px 5px 20px 5px !important;
  }

  .admin_modal_pa {
    width: 95% !important;
    padding: 0px 5px 20px 5px !important;
  }

  .property_padding {
    padding: 5px !important;
  }
  .cross_drawer {
    display: block !important;
  }
  .width_drawer {
    width: 100% !important;
  }
  .property_Modal_Padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .image_Width {
    width: 100px !important;
  }
  .image_Margin {
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }
  .justify {
    justify-content: center !important;
  }
  .column {
    flex-direction: column !important ;
  }
  .margin {
    margin-bottom: 5px !important;
  }
  .width_agent {
    width: 65% !important;
  }
  .video_property {
    width: 100% !important;
  }
}

@media only screen and (max-width: 480px) {
  /* .login_logo {
        text-align: center !important;
    }

    .login_logo img {
        display: inline-block !important;
        vertical-align: middle !important;
    } */

  .heading_center {
    text-align: center !important;
  }

  .image_center {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .top_options {
    display: flex !important;
    flex-direction: column !important;
    height: 340px !important;
  }

  .top_column {
    display: flex !important;
    flex-direction: column !important;
  }

  .width_full {
    width: 100% !important;
  }

  .width_full_property {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .width_full_status {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .width_full_property_price {
    width: 100% !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .property_modal_header {
    padding: 20px 0px 12px 5px !important;
  }

  .margin_property {
    margin-right: 5px !important;
    padding-left: 5px !important;
  }

  .property_checkbox {
    width: 95% !important;
  }

  .admin_modal {
    width: 95% !important;
    padding: 20px 5px 20px 5px !important;
  }

  .admin_modal_pa {
    width: 95% !important;
    padding: 0px 5px 20px 5px !important;
  }

  .property_padding {
    padding: 5px !important;
  }
  .cross_drawer {
    display: block !important;
  }
  .width_drawer {
    width: 100% !important;
  }
  .property_Modal_Padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .image_Width {
    width: 100px !important;
  }
  .image_Margin {
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }
  .justify {
    justify-content: center !important;
  }
  .column {
    flex-direction: column !important ;
  }
  .margin {
    margin-bottom: 5px !important;
  }
  .width_agent {
    width: 65% !important;
  }
  .video_property {
    width: 100% !important;
  }
}

.one-line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; /* Number of lines to show */
  font-size: 12px;
  font-weight: bold;
}

.p-treeselect-clear-icon {
  position: relative !important;
  left: 0% !important;
  top: 0% !important;
  margin-top: 0px !important;
}

.p-treeselect-panel {
  z-index: 1001 !important;
  position: absolute !important;
  top: 60px !important;
  left: 0px !important;
  transform-origin: center bottom !important;
  padding: 5px;
}

.p-treeselect-filter {
  /* height: 50 !important; */
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-treeselect-filter-icon {
  position: absolute !important;
  top: 34% !important;
}

.p-treeselect-header {
  margin: 5px !important;
  display: flex !important;
  flex-direction: row-reverse !important;
  display: none !important;
}
/* .p-treeselect-items-wrapper {
  margin: 5px !important;
} */
.p-treeselect-items {
  padding: 5px !important;
}
.p-treenode-content {
  padding: 5px !important;
  margin-top: 5px !important;
}
.p-treeselect-close {
  margin: 5px !important;
}
.p-treenode-children {
  padding-left: 10px !important;
}
.p-treetable-emptymessage {
  text-align: center !important;
  font-size: 16px !important; /* Adjust font size as needed */
  color: #000000 !important; /* Adjust color as needed */
  padding: 20px !important; /* Adjust padding as needed */
  padding-left: 20px !important;
}
.p-treetable-tbody {
  padding: 20px !important;
  text-align: center !important;
}
.ql-editor {
  min-height: 150px !important;
}
.routerLink {
  color: #0000ee;
}
.routerLinkDisablity {
  color: rgba(255 54 1);
}

.apexcharts-canvas {
  right: 30px !important;
  bottom: 30px;
}